import { Injectable, Injector } from '@angular/core';

import { BaseResourceService } from '../../shared/services/base-resource.service';
import { Notification } from './notification.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseResourceService<Notification> {

    constructor(protected injector: Injector) {

        super('notifications', injector, Notification.fromJson);
    }
}
