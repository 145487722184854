import { BaseResourceModel } from '../../shared/models/base-resource.model';

export class Notification extends BaseResourceModel {

    constructor(
        public id?: number,
        public name?: string,
        public content?: string,
        public type?: string,
        public slug?: string,
        public status?: number,
        public auto_delete_at?: string,
        public created_at?: string,
        public selected?: boolean,
    ) {
        super();
    }

    static fromJson(jsonData: any): Notification {
        return Object.assign(new Notification(), jsonData);
    }
}
